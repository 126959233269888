import { Box, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRest } from 'components/utils/Rest';
import BranchesTable from 'components/mainPage/BranchesTable';
import Page from '../components/theme/Page';
import { useInterval } from 'usehooks-ts';

// ----------------------------------------------------------------------

export default function UnavailableBranches() {
  const { rest } = useRest();

  const [branchesData, setBranchesData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pauseDataFetching, setPauseDataFetching] = useState(false);
  
  async function fetchData() {
    const data = await rest('get', '/branch-unavailables');
    setBranchesData(data);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  useInterval(
    fetchData,
    pauseDataFetching ? null : 5000, // interval in milliseconds
  );

  const pageName = "Unavailable Branches";
  return (
    <Page title="Main">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 2 }}>{pageName}</Typography>
        <Box sx={{ pb: 5 }}>
          {
            loading ? "Loading..."
              :
              <BranchesTable branches={branchesData.branches} showCompanyColumn={true} pauseDataFetchingState={setPauseDataFetching}/>
          }
        </Box>
      </Container>
    </Page>
  );
}
