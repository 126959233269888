const serverDomain = "http://" + window.location.hostname + ":3001";

const useRest = () => {
  const handleFetch = async (method, api, body) => {
    const token = sessionStorage.getItem('token');
    if (api !== "/login") {
      console.log("rest", api, body ? body : "");
    }
    let headers;
    if (token) {
      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
    } else {
      headers = {
        'Content-Type': 'application/json'
      };
    }
    const response = await fetch(`${serverDomain}/api${api}`, {
      method: method,
      headers: headers,
      body: JSON.stringify(body)
    });

    const res = await response.json();
    console.log("  >>>>", res);
    return res;
  };

  return { rest: handleFetch };
}

export { useRest };