// material
import { Box, Container, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// components
import Page from '../components/theme/Page';
import CompaniesTable from '../components/mainPage/CompaniesTable';
import { useTranslation } from '../components/i18next';
import { useState } from 'react';


// ----------------------------------------------------------------------

function getViewMode() {
  if (localStorage.getItem('companies-view-mode') === null)
    return false;
  else {
    return localStorage.getItem('companies-view-mode') === "true";
  }
}

function setViewMode(mode) {
  localStorage.setItem('companies-view-mode', mode);
}

export default function DashboardApp() {
  const [minimized, setMinimized] = useState(getViewMode());

  return (
    <Page title="Main">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 2 }}>Companies</Typography>
        <Box sx={{ pb: 5 }}>
          <ToggleButtonGroup
            color="primary"
            value={minimized}
            exclusive
            onChange={
              (e, value) => {
                if (value === null) {
                  value = !minimized
                };
                setMinimized(value)
                setViewMode(value);
              }
            }
            aria-label="Platform"
          >
            <ToggleButton value={false}>Normal</ToggleButton>
            <ToggleButton value={true}>Minimized</ToggleButton>
          </ToggleButtonGroup><br /><br />
          <CompaniesTable minimized={minimized} />
        </Box>
      </Container>
    </Page>
  );
}
