import { useRest } from '../utils/Rest';
import TableRow from '@mui/material/TableRow';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TableCell from '@mui/material/TableCell';
import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import CancelIcon from '@mui/icons-material/Cancel';
import ListItemIcon from '@mui/material/ListItemIcon';
import HeightIcon from '@mui/icons-material/Height';
import { pink, orange } from '@mui/material/colors';
import { dateToString, useServerConfig, capitalizeFirstLetter } from 'components/utils/common';
import Switch, { Case } from 'react-switch-case';

export default function LogList({ objectID, isCompany }) {
    const [logs, setLogs] = useState({ error: "Loading..." });
    const { rest } = useRest();
    const { fetchServerConfig, serverConfigState } = useServerConfig();

    useEffect(async () => {
        fetchServerConfig();
        const logUrl = isCompany ? `/company/log/${objectID}` : `/branch/log/${objectID}`;
        const data = await rest('get', logUrl);
        setLogs(data);
    }, []);

    return (
        <TableRow>
            <TableCell colSpan={12}>
                <List
                    sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                        position: 'relative',
                        overflow: 'auto',
                        maxHeight: 300,
                        '& ul': { padding: 0 },
                    }}
                    subheader={<li />}
                >
                    {
                        logs.error || logs.map((entry) => (
                            <ListItem key={`entry-${entry._id}`}>
                                <ListItemIcon>
                                    <Switch condition={entry.event}>
                                        <Case value={serverConfigState.LOG_EVENTS?.Connected}><CheckCircleIcon color="primary" /></Case>
                                        <Case value={serverConfigState.LOG_EVENTS?.Disconnected}><CancelIcon sx={{ color: pink[500] }} /></Case>
                                        <Case value={serverConfigState.LOG_EVENTS?.Notified}><MarkEmailReadIcon sx={{ color: orange[500] }} /></Case>
                                        <Case value={serverConfigState.LOG_EVENTS?.Updown}><HeightIcon sx={{ color: orange[500] }} /></Case>
                                    </Switch>
                                </ListItemIcon>
                                <ListItemText primary={dateToString(entry.date) + " - " + capitalizeFirstLetter(entry.event)} />
                            </ListItem>
                        ))
                    }
                </List>
            </TableCell>
        </TableRow>
    )
}