import { useNavigate } from "react-router-dom";

// material
import { useState, useEffect } from 'react';
import { useRest } from '../utils/Rest';
import { Box, Container, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import MUIDataTable from "mui-datatables";
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CompanyForm from './CompanyForm';
import LogList from './LogList';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { capitalizeFirstLetter, getStatusColor, dateToString, dateIsAfter } from 'components/utils/common';
import AlertPopup from 'components/utils/AlertPopup';
import { useInterval } from 'usehooks-ts';
import Card from '@mui/material/Card';
import { CardMedia, CardActions, CardActionArea, CardContent, CardHeader, Collapse } from '@mui/material';
import Grid from '@mui/material/Grid';

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function CompanyActions({ company, setItemToAction }) {
  return (
    <>
      <IconButton
        component="button"
        variant="body2"
        onClick={(event) => {
          event.stopPropagation();
          window.open('/dashboard/' + company.id, "_blank");
        }}
      >
        <OpenInNewIcon />
      </IconButton>
      <IconButton
        component="button"
        variant="body2"
        onClick={(event) => {
          event.stopPropagation();
          setItemToAction({ action: 'edit', item: company, index: -1 });
        }}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        component="button"
        variant="body2"
        onClick={(event) => {
          event.stopPropagation()
          setItemToAction({ action: 'delete', item: company, index: -1 });
        }}
      >
        <DeleteIcon />
      </IconButton>
    </>)
}

function CompanyCard({ element, setItemToAction }) {
  let navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ width: 195 }}>
      <CardActionArea onClick={event => navigate('/dashboard/' + element.raw.id, { state: { company: element.raw } })}>
        <CardMedia
          component="img"
          sx={{ height: 50, padding: "1em 1em 0 1em", objectFit: "contain" }}
          image={element.IconPath}
          title={element.Company}
        />
        <CardHeader
          title={element.Company}
          subheader={(<><Typography variant="subtitle2" color={getStatusColor(element.Status)}>{element.Status}</Typography><Typography variant="subtitle2">{element.Availability}</Typography></>)}
        />
      </CardActionArea>
      <CardActions>
        <CompanyActions company={element.raw} setItemToAction={setItemToAction} />
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <LogList objectID={element.raw.id} isCompany={true} />
        </CardContent>
      </Collapse>
    </Card>
  );
}

function CompaniesCards({ dataTable, setItemToAction }) {
  return (
    <Grid container spacing={1}>
      {dataTable.map(e => (
        <Grid item key={e.raw.id}><CompanyCard element={e} setItemToAction={setItemToAction} /></Grid>
      ))}
    </Grid>);
}

export function CalcBranchesStats(company) {
  let availableBranches = 0;
  let totalOpenBranches = 0;
  let unknownBranches = 0;
  company.branches.forEach((branch) => {
    if (branch.unknown) {
      unknownBranches++;
      return;
    } else {
      if (!branch.open)
        return;

      totalOpenBranches++;
      if (branch.status === "available") {
        availableBranches++;
      }
    }
  });

  let res = `${availableBranches}/${totalOpenBranches}`;
  if (unknownBranches > 0)
    res += ` (+${unknownBranches} unknown)`;

  return (res);
}

export default function CompaniesTable({ minimized }) {
  let navigate = useNavigate();

  const idColumn = {
    name: "ID",
    options: {
      display: false,
    }
  };

  const actionsColumn = {
    name: "Action",
    options: {
      filter: true,
      sort: false,
      empty: true,
      customBodyRenderLite: (dataIndex, rowIndex) => {
        return (<CompanyActions company={dataTable[dataIndex].raw} setItemToAction={setItemToAction} />);
      }
    }
  };

  const columns = [idColumn, "Icon", "Company", "Availability", "Status",
    {
      name: "Started At",
      options: {
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const date1 = dataTable[obj1.position].raw.statusDate;
            const date2 = dataTable[obj2.position].raw.statusDate;
            const sortOrder = dateIsAfter(new Date(date1), new Date(date2)) ? 1 : -1;
            return sortOrder * (order === 'asc' ? 1 : -1);
          };
        }
      }
    },
    actionsColumn];

  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(true);
  const { rest } = useRest();
  const [itemToAction, setItemToAction] = useState(null);
  const [sortColumn, setSortColumn] = useState({
    name: 'Company',
  });
  const [shouldPauseFetchingData, setShouldPauseFetchingData] = useState(false);

  async function fetchData() {
    const companies = await rest('get', '/companies');
    let dataTable = [];
    companies.forEach((company) => {
      const stats = CalcBranchesStats(company);
      let path = window.location.origin + "/media/companies_logos/" + company.logo;
      path = path.replace('/media', ':3001/media');

      let status = capitalizeFirstLetter(company.status);
      status = minimized ? status : (<Typography color={getStatusColor(company.status)}>{status}</Typography>);

      dataTable.push({
        "Icon": <img src={path} width="150px" height="50px" alt="company logo" />,
        "IconPath": path,
        "Company": company.name,
        "Availability": stats,
        "Status": status,
        "Started At": dateToString(company.statusDate),
        raw: company,
      });
    });
    setDataTable(dataTable);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  useInterval(
    fetchData,
    shouldPauseFetchingData ? null : 5000 // interval in milliseconds
  );

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'standard',
    onRowClick: (rowData, rowMeta) => {
      navigate('/dashboard/' + dataTable[rowMeta.dataIndex].raw.id, { state: { company: dataTable[rowMeta.dataIndex].raw } });
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    expandableRowsOnClick: false,
    expandableRows: true,
    rowsPerPage: 100,
    sortOrder: sortColumn,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <LogList objectID={dataTable[rowMeta.dataIndex].raw.id} isCompany={true} />
      );
    },
    onColumnSortChange: (changedColumn, direction) => {
      setSortColumn({ changedColumn, direction });
    },
    searchAlwaysOpen: true,
    onTableChange: (action, tableState) => {
      setShouldPauseFetchingData(tableState.expandedRows.data.length > 0);
    }
  };


  function editCallback(newCompany) {
    // Only company name & logo is relevant to be updated
    if (itemToAction.index > -1) {
      dataTable[itemToAction.index]["Company"] = newCompany.name;
    }
    // TODO: logo hot update

    setItemToAction(null); // Closes edit dialog
  }

  const alertDialogOptions = {
    title: "Delete company",
    body: `Are you sure you want to delete '${itemToAction?.item.name}' ?`,
    onConfirm: async () => {
      const companyId = itemToAction?.item.id;
      const response = await rest('delete', `/company/${companyId}`);
      if (response.success) {
        dataTable.splice(itemToAction?.index, 1);
      }
      setItemToAction(null);
    },
    onCancel: () => {
      setItemToAction(null);
    }
  }

  const getMuiTheme = () => createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            cursor: "pointer"
          }
        }
      }
    }
  })

  return (
    <Container maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        {
          loading ? (
            <Typography variant="body1">Loading...</Typography>
          ) : (
            <>
              <Box>
                <AlertPopup options={alertDialogOptions} open={itemToAction?.action === 'delete'} />
              </Box>
              <Box>
                <Dialog open={itemToAction?.action === 'edit'} onClose={() => { setItemToAction(null); }}>
                  <DialogTitle>Company {itemToAction?.item.name}</DialogTitle>
                  <DialogContent>
                    <CompanyForm itemToEdit={itemToAction?.item} submitCallback={editCallback} />
                  </DialogContent>
                </Dialog>
              </Box>
              {
                minimized ?
                  (<CompaniesCards dataTable={dataTable} setItemToAction={setItemToAction} />) : (
                    <Box>
                      <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                          data={dataTable}
                          columns={columns}
                          options={options}
                        />
                      </ThemeProvider>
                    </Box>
                  )
              }
            </>
          )
        }
      </Box >
    </Container >
  );
}
