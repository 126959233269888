import * as React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import CFMForm from '../../components/utils/CFMForm';

export default function AddItemForm({ title, onSubmit, initialValues, validationSchema, children }) {
    return (
        <>
            <CFMForm
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                initialValues={initialValues}
            >
                <Card><CardContent>
                    <Typography variant="h5">{title}</Typography>
                    {children}
                </CardContent></Card>
            </CFMForm>
        </>
    );
}