import { Box, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRest } from 'components/utils/Rest';
import BranchesTable from 'components/mainPage/BranchesTable';
import Page from '../components/theme/Page';

// ----------------------------------------------------------------------

export default function UnknownPings() {
  const { rest } = useRest();

  const [branchesData, setBranchesData] = useState(null);
  const [loading, setLoading] = useState(true);


  useEffect(async () => {
    const data = await rest('get', '/branch-unknown-pings');
    setBranchesData(data);
    setLoading(false);
  }, []);

  const pageName = "Unknown Pings";
  return (
    <Page title="Main">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 2 }}>{pageName}</Typography>
        <Box sx={{ pb: 5 }}>
          {
            loading ? "Loading..."
              :
              <BranchesTable branches={branchesData.branches} pingMode={true} />
          }
        </Box>
      </Container>
    </Page>
  );
}
