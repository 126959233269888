import { useState, useEffect } from 'react';
import AddItemForm from './AddItemForm';
import { useRest } from '../../components/utils/Rest';
import { capitalizeFirstLetter } from 'components/utils/common';
// material
import { Box, Container, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
// components
import MUIDataTable from "mui-datatables";
import Page from '../../components/theme/Page';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// ----------------------------------------------------------------------

export default function ItemManager({ itemName, itemColumns, buildTableEntry, getInitValues, validationSchema, children }) {
  const [tableData, setTableData] = useState([]);
  const [itemToEdit, setitemToEdit] = useState(null);
  const { rest } = useRest();

  let columns = [
    {
      name: "Action",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <>
              <IconButton
                component="button"
                variant="body2"
                onClick={(event) => {
                  editAction({ index: rowIndex, id: tableData[dataIndex].id });
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                component="button"
                variant="body2"
                onClick={(event) => {
                  removeAction(({ index: rowIndex, id: tableData[dataIndex].id }))
                }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          );
        }
      }
    }];

  columns = columns.concat(itemColumns ? itemColumns : []);

  async function addSubmit(values) {
    const res = await rest('post', `/${itemName}/add`, values);

    if (res.success) {
      setTableData([...tableData, buildTableEntry(res.newItem)]);
    }

    return res;
  }

  async function editSubmit(values) {
    let data = tableData;
    const res = await rest('post', `/${itemName}/edit/${itemToEdit.item._id}`, values);

    if (res.success) {
      data[itemToEdit.index] = buildTableEntry(res.newItem);
      setTableData(data);
      setitemToEdit(null); // Closes edit dialog
    }

    return res;
  }

  async function editAction(rowData) {
    const item = await rest('get', `/${itemName}/${rowData.id}`);
    setitemToEdit({ index: rowData.index, item: item });
  }

  async function removeAction(rowData) {
    const res = await rest('post', `/${itemName}/remove`, { id: rowData.id });

    if (res.success) {
      setTableData(tableData.filter((row) => row.id !== rowData.id));
    }
  }

  useEffect(async () => {
    const items = await rest('get', `/${itemName}s`);
    let tableData = [];
    items.forEach((item) => {
      tableData.push(buildTableEntry(item));
    });
    setTableData(tableData);
  }, []);

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'standard',
    expandableRows: false,
    expandableRowsHeader: false,
    selectableRows: 'none',
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
  };

  return (
    <Page title="Settings | ClickFM">
      <Dialog open={itemToEdit ? true : false} onClose={() => { setitemToEdit(null); }}>
        <DialogTitle>Edit {capitalizeFirstLetter(itemName)}</DialogTitle>
        <DialogContent>
          <AddItemForm
            onSubmit={editSubmit}
            user={itemToEdit?.item}
            initialValues={getInitValues(itemToEdit?.item)}
            validationSchema={validationSchema}
          >
            {children}
          </AddItemForm>
        </DialogContent>
      </Dialog>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 2 }}>{capitalizeFirstLetter(itemName)}s</Typography>
        <Box sx={{ pb: 5 }}>
          <MUIDataTable
            data={tableData}
            columns={columns}
            options={options}
          />
        </Box>
        <AddItemForm title={`Add ${capitalizeFirstLetter(itemName)}`} onSubmit={addSubmit} initialValues={getInitValues(null)}>
          {children}
        </AddItemForm>
      </Container>
    </Page>
  );
}
