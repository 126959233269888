import { DateTime } from "luxon";
import { useRest } from 'components/utils/Rest';
import { useState } from 'react';
import { differenceInHours, differenceInMinutes, isAfter } from 'date-fns'

export function log(str) {
    console.log(str);
}

export function dateToLocal(date) {
    return DateTime.fromISO(date);
}

export function dateToString(date) {
    return dateToLocal(date).toLocaleString({ ...DateTime.DATETIME_SHORT, hourCycle: 'h23' });
}

export function dateToRelative(date) {
    return dateToLocal(date).toRelative();
}

export function useServerConfig() {
    const { rest } = useRest();
    const [serverConfigState, setServerConfigState] = useState({});

    const fetchServerConfig = () => {
        rest('get', '/config').then(config => { setServerConfigState(config); });
    }

    const asyncFetchServerConfig = async () => {
        const config = await rest('get', '/config');
        setServerConfigState(config);
        return config;
    }

    return { asyncFetchServerConfig, fetchServerConfig, serverConfigState }
}

export function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function config() {
    return {
        STATUS_YELLOW_TO_READ_TRANSITION_HOURS: 5,
        MARK_OFFLINE_AFTER_NO_PING_MINUTES: 30,
    }
}

export function getStatusColor(status, statusDate) {
    const delta = differenceInHours(new Date(), new Date(statusDate));
    let color;

    if (typeof(status) !== "string") {
        return "";
    }

    switch (status.toLowerCase()) {
        case "available":
            color = "green";
            break;
        case "unavailable":
            color = (delta < config().STATUS_YELLOW_TO_READ_TRANSITION_HOURS) ? "orange" : "red";
            break;
    }

    return color;
}

export const diffInMinutes = differenceInMinutes;

export function dateIsAfter(date1, date2) {
    return isAfter(date1, date2);
}