import { Box, Container, Typography, Button } from '@mui/material';
import * as Yup from 'yup';
import Page from '../components/theme/Page';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Field } from 'formik';
import { ToggleButton } from '@mui/material';
import { Switch, ToggleButtonGroup, TextField } from 'formik-mui';
import CFMForm from '../components/utils/CFMForm';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useRest } from '../components/utils/Rest';
import { useNavigate } from "react-router-dom";
import { ImageInput } from "formik-file-and-image-input/lib";
import CompanyForm from 'components/mainPage/CompanyForm';

export default function DashboardApp() {
  return (
    <Page title="New Company">
      <Container>
        <Typography variant="h4">Create a new company</Typography>
        <CompanyForm />
      </Container >
    </Page >
  );
}
