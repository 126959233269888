import { useState, useEffect } from 'react';
import { useRest } from '../../components/utils/Rest';
// material
import { Container } from '@mui/material';
// components
import * as Yup from 'yup';
import ItemManager from './ItemManager';
import Page from '../../components/theme/Page';
import { Field } from 'formik';
import { Box } from '@mui/material';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import Typography from '@mui/material/Typography';

// ----------------------------------------------------------------------

export default function SettingsUsers() {
  const [companies, setCompanies] = useState([]);
  const { rest } = useRest();

  useEffect(() => {
    rest('get', '/companies').then(data => setCompanies(data));
  }, []);

  function buildUserEntry(user) {
    return {
      id: user._id,
      Name: user.name,
      Companies: user.companies.length > 0 ? user.companies.map((company) => company.name).join(', ') : "ADMIN",
    };
  }

  function getInitValues(user) {
    return {
      user: user ? user.user : '',
      name: user ? user.name : '',
      pass: user ? user.pass : '',
      companies: user ? user.companies.map((company) => company._id) : [],
    };
  }

  const schema = Yup.object().shape({
    user: Yup.string().email().required("This field is required"),
    pass: Yup.string().required("This field is required"),
  });

  return (
    <Page title="Settings | ClickFM">
      <Container maxWidth="xl">
        <ItemManager
          itemName="user"
          buildTableEntry={buildUserEntry}
          getInitValues={getInitValues}
          itemColumns={["Name", "Companies"]}
          validationSchema={schema}
        >
          <Box margin={1}>
            <Field
              component={TextField}
              name="user"
              type="email"
              label="Email"
              helperText="Please Enter Email"
            />
          </Box>
          <Box margin={1}>
            <Field
              component={TextField}
              name="name"
              label="Name"
              helperText="Please Enter Name"
            />
          </Box>
          <Box margin={1}>
            <Field
              component={TextField}
              type="password"
              label="Password"
              name="pass"
            />
          </Box>

          <Box margin={1}>
            <Typography>Companies:</Typography>
            {companies.map(company => (
              <div key={company.id}>
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  Label={{ label: company.name }}
                  name="companies"
                  value={company.id}
                /><br />
              </div>
            ))}
          </Box>
        </ItemManager>
      </Container>
    </Page>
  );
}
