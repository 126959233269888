import { useSearchParams } from 'react-router-dom'

export default function Ping() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("ID");
  const last_ftp = searchParams.get("Last-ftp-sync");
  const ip = searchParams.get("IP");
  const mac = searchParams.get("MAC");
  const android_version = searchParams.get("Android-Version");
  const app_version = searchParams.get("AppVersion");

  window.location.replace(`http://status.musiclick.co.il:3001/api/ping?ID=${id}&Last-ftp-sync=${last_ftp}&IP=${ip}&MAC=${mac}&Android-Version=${android_version}&AppVersion=${app_version}`);
}