// material
import { Box, Container, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import MUIDataTable from "mui-datatables";
import LogList from './LogList';
import { dateToString, dateToRelative, capitalizeFirstLetter, getStatusColor, dateIsAfter, config, diffInMinutes, dateToLocal } from 'components/utils/common';
import DeleteIcon from '@mui/icons-material/Delete';
import CellTowerIcon from '@mui/icons-material/CellTower';
import BranchForm from './BranchForm';
import { useState, useEffect } from 'react';
import { useRest } from '../../components/utils/Rest';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AlertPopup from 'components/utils/AlertPopup';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import Tooltip from '@mui/material/Tooltip';

// ----------------------------------------------------------------------
export default function BranchesTable({ branches, ownerCompany, showCompanyColumn, pauseDataFetchingState = () => false, pingMode = false }) {
  const idColumn = {
    name: "ID",
    options: {
      display: false,
    }
  };

  const actionsColumn = {
    name: "Action",
    options: {
      customBodyRenderLite: (dataIndex, rowIndex) => {
        const positionStyle = { position: "absolute" };
        const isUnknown = dataTable[dataIndex]?.raw.unknown;
        const isAddable = ownerCompany && isUnknown;

        return (
          <>
            {
              (isAddable || !isUnknown) ?
                <IconButton
                  component="button"
                  onClick={(event) => {
                    event.stopPropagation()
                    editAction({ index: dataIndex, id: dataTable[dataIndex].ID });
                  }}
                >
                  {
                    isAddable ? < AddBusinessIcon style={{ ...positionStyle }} /> :
                      !isUnknown ? <EditIcon style={{ marginRight: 20, ...positionStyle }} /> : ""
                  }

                </IconButton> : ""
            }
            {
              !isUnknown ?
                <IconButton
                  component="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeAction(({ index: dataIndex, id: dataTable[dataIndex].ID }));
                  }}
                >
                  < DeleteIcon style={{ ...positionStyle }} />
                </IconButton>
                : ""
            }
          </>
        );
      },
      display: !ownerCompany || ownerCompany.userIsAdmin ? "true" : "excluded"
    }
  };
  const { rest } = useRest();
  const [itemToEdit, setItemToEdit] = useState(null);
  const [dataTable, setDataTable] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [sortColumn, setSortColumn] = useState({
    name: 'Status',
    direction: 'desc'
  });

  let columns = [idColumn, "Name", "Number", "Client Number", "Player Type",
    {
      name: "Status",
      options: {
        sortCompare: (order) => {
          return (obj1, obj2) => {
            return obj1.data.localeCompare(obj2.data) * (order === 'asc' ? 1 : -1);
          };
        },
        customBodyRenderLite: (dataIndex) => {
          const data = dataTable[dataIndex];
          let lastPingIndicator = null;
          let subChannelIndicator = null;

          if (!data)
            return;

          if (diffInMinutes(new Date(), new Date(data.raw.lastPing)) < config().MARK_OFFLINE_AFTER_NO_PING_MINUTES) {
            lastPingIndicator = (<Tooltip title={"Last Ping: " + dateToString(data.raw.lastPing)}><CellTowerIcon fontSize="small" /></Tooltip>);
          }

          if (data.raw.subchannelName) {
            subChannelIndicator = (<Tooltip title={"On subchannel: " + data.raw.subchannelName}><AltRouteIcon fontSize="small" /></Tooltip>);
          }

          return (< Typography color={getStatusColor(data.raw.status, data.raw.statusDate)} >{data["Status"]} {lastPingIndicator}{subChannelIndicator}</Typography >);
        }
      }
    },
    {
      name: "Started At",
      options: {
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const date1 = dataTable[obj1.position].raw.statusDate;
            const date2 = dataTable[obj2.position].raw.statusDate;
            const sortOrder = dateIsAfter(new Date(date1), new Date(date2)) ? 1 : -1;
            return sortOrder * (order === 'asc' ? 1 : -1);
          };
        },
        display: !ownerCompany || ownerCompany.showHistory ? "true" : "excluded"
      }
    },
    {
      name: "Total Time",
      options: {
        sort: false,
        display: !ownerCompany || ownerCompany.showHistory ? "true" : "excluded"
      }
    },
    {
      name: "Remarks",
      options: {
        display: !ownerCompany || ownerCompany.userIsAdmin ? "true" : "excluded"
      }
    },
    actionsColumn
  ];

  if (pingMode) {
    columns[6] = {
      name: "Last Ping",
      options: {
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const date1 = dataTable[obj1.position].raw.lastPing;
            const date2 = dataTable[obj2.position].raw.lastPing;
            const sortOrder = dateIsAfter(new Date(date1), new Date(date2)) ? 1 : -1;
            return sortOrder * (order === 'asc' ? 1 : -1);
          };
        },
        display: !ownerCompany || ownerCompany.showHistory ? "true" : "excluded"
      }
    };
  }

  if (showCompanyColumn) {
    columns.splice(1, 0, "Company");
  }

  const buildTableEntry = (branch) => {
    let status, res;

    if (branch.unknown) {
      status = "Unknown";
    } else {
      status = branch.open ? capitalizeFirstLetter(branch.status) : "Not active";
    }

    res = {
      ID: branch.id,
      Name: branch.name,
      Number: parseInt(branch.number) || "",
      "Client Number": parseInt(branch.clientNumber) || "",
      "Player Type": branch.playerType,
      "Status": status,
      "Started At": dateToString(branch.statusDate),
      "Total Time": dateToRelative(branch.statusDate),
      "Remarks": branch.remarks,
      "Company": branch.companyID.name,
      raw: branch,
    };

    if (pingMode) {
      res["Last Ping"] = dateToString(branch.lastPing);
      res["Total Time"] = dateToRelative(branch.lastPing);
    }

    return res;
  };

  useEffect(() => {
    let data = [];
    branches?.forEach((branch) => {
      branch.statusDate = dateToLocal(branch.statusDate);
      branch.lastPing = dateToLocal(branch.lastPing);
      data.push(buildTableEntry(branch));
    });
    setDataTable(data);
  }, [branches]);

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'standard',
    expandableRows: ownerCompany?.showHistory,
    expandableRowsHeader: false,
    sortOrder: sortColumn,
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    expandableRowsOnClick: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [10, 50, 100, 500],
    renderExpandableRow: (rowData, rowMeta) => {
      let branchID = rowData[0];
      return (
        <LogList objectID={branchID} isCompany={false} />
      );
    },
    onColumnSortChange: (changedColumn, direction) => {
      setSortColumn({ changedColumn, direction });
    },
    searchAlwaysOpen: true,
    onTableChange: (action, tableState) => {
      pauseDataFetchingState(tableState.expandedRows.data.length > 0);
    }
  };

  async function editAction(rowData) {
    const item = await rest('get', `/branch/${rowData.id}`);
    setItemToEdit({ index: rowData.index, item: item });
  }

  function editCallback(data) {
    let table = dataTable;

    table[itemToEdit.index] = buildTableEntry(data);
    setDataTable(table);
    setItemToEdit(null); // Closes edit dialog
  }

  async function removeAction(rowData) {
    const item = await rest('get', `/branch/${rowData.id}`);
    setItemToDelete({ index: rowData.index, item: item });
  }


  const alertDialogOptions = {
    title: "Delete branch",
    body: "Are you sure you want to delete this branch?",
    onConfirm: async () => {
      const branchId = itemToDelete.item._id;
      const response = await rest('delete', `/branch/${branchId}`);
      if (response.success) {
        dataTable.splice(itemToDelete.index, 1);
      }
      setItemToDelete(null);
    },
    onCancel: () => {
      setItemToDelete(null);
    }
  }

  const getMuiTheme = () => createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            height: 15,
            marginBottom: 5,
          },
        }
      }
    }
  })

  return (
    <Container maxWidth="xl">
      <Box>
        <AlertPopup options={alertDialogOptions} open={itemToDelete ? true : false} />
      </Box>
      <Box>
        <Dialog open={itemToEdit ? true : false} onClose={() => { setItemToEdit(null); }}>
          <DialogTitle>Branch {itemToEdit?.item.name} {ownerCompany ? `| Company ${ownerCompany.name}` : ""}</DialogTitle>
          <DialogContent>
            <BranchForm itemToEdit={itemToEdit?.item} submitCallback={editCallback} />
          </DialogContent>
        </Dialog>
      </Box>
      <Box sx={{ pb: 5 }}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            data={dataTable}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </Box>
    </Container>
  );
}
