import { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';


export default function MediaControlCard({ url, title, singer, image, src }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card variant="outlined" sx={{ display: 'flex', width: "90%" }}>
      <Grid container item>
        <Grid item xs={5}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography component="div" variant="h5">
              {title}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" component="div">
              {singer}
            </Typography>
          </CardContent>
        </Grid>

        <Grid item xs={7}>
          <Box
            component="img"
            sx={{
              width: '100%',
              height: '100%',
              maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 350, md: 250 },
              borderBottomLeftRadius: '20px',
            }}
            alt={title}
            src={image}
          />

        </Grid>

        <Grid item xs={12}>
          <Box>
            {
              isOpen
                ? <audio style={{ width: "100%" }} src={src} controls />
                : <Button sx={{ m: 1 }} variant="outlined" color="secondary" onClick={() => setIsOpen(true)}>Show play</Button>
            }
          </Box>
        </Grid>

      </Grid>
    </Card >
  );
}
