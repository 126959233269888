import { useState } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import { ProtectedRoute, AdminRoute } from './components/Login/AuthContext';

//
import DashboardApp from './pages/DashboardApp';
import DashboardCompany from './pages/DashboardCompany';
import SettingsUsers from './pages/settings/SettingsUsers';
import SettingsSubchannels from './pages/settings/SettingsSubchannels';
import NewCompany from './pages/NewCompany';
import NotFound from './pages/Page404';
import NotAuthorized from './pages/NotAuthorized';
import UnavailableBranches from 'pages/UnAvailableBranches';
import UnknownPings from 'pages/UnknownPings';
import Ping from 'pages/Ping';

// ----------------------------------------------------------------------

export default function Router() {
  const [loggedUser, setLoggedUser] = useState(null);

  function logoutUser() {
    setLoggedUser(null);
  }

  return (
    <Routes>
      <Route element={<ProtectedRoute loggedUser={loggedUser} setLoggedUser={setLoggedUser}><DashboardLayout loggedUser={loggedUser} logoutUser={logoutUser} /></ProtectedRoute>}>
        <Route index element={<Navigate to="/dashboard" />} />
        <Route path="dashboard/:id" element={<DashboardCompany />} />
        <Route path="dashboard" element={<DashboardApp />} />
        <Route path="unavailable-branches" element={<UnavailableBranches />} />
        <Route path="unknown-pings" element={<UnknownPings />} />
      </Route>
      <Route element={<AdminRoute loggedUser={loggedUser} setLoggedUser={setLoggedUser}><DashboardLayout loggedUser={loggedUser} logoutUser={logoutUser} /></AdminRoute>}>
        <Route path="settings/users" element={<SettingsUsers />} />
        <Route path="settings/subchannels" element={<SettingsSubchannels />} />
        <Route path="new-company" element={<NewCompany />} />
      </Route>
      <Route element={<LogoOnlyLayout />}>
        <Route path="login" element={<Navigate to="/dashboard" />} />
        <Route path="404" element={<NotFound />} />
        <Route path="not-authorized" element={<NotAuthorized />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>
      <Route>
        <Route path="newStatus/ping.php" element={<Ping />} />
      </Route>
    </Routes>
  );
}
