import React from 'react';
import { Navigate } from 'react-router-dom';
import Login from '../../pages/Login';


const useAuth = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  const handleUser = (value) => {
    localStorage.setItem('user', JSON.stringify(value));
  };

  const handleForget = () => {
    localStorage.clear();
    sessionStorage.clear();
  };

  return { user: user, setUser: handleUser, forget: handleForget };
}

const ProtectedRoute = ({ children, loggedUser, setLoggedUser }) => {
  const { user } = useAuth();

  if (loggedUser === null) {
    return (<Login setLoggedUser={setLoggedUser} userCredentials={user} />);
  } else {
    return (children);
  }
};

const AdminRoute = ({ children, loggedUser, setLoggedUser }) => {
  const { user } = useAuth();

  if (loggedUser === null) {
    return (<Login setLoggedUser={setLoggedUser} userCredentials={user} />);
  } else if (loggedUser.companies.length > 0) {
    return (<Navigate to="/not-authorized" />);
  } else {
    return (children);
  }
};

export { useAuth, ProtectedRoute, AdminRoute };