import './App.css';
import Router from './routes';

// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/theme/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';

function App() {
  if (window.location.host.split('.')[0] === 'music' && !window.location.pathname.includes('/music')) {
    window.location.pathname = '/music' + window.location.pathname;
  }

  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <Router />
    </ThemeConfig>
  );
}

export default App;
