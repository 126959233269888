import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useRest } from '../components/utils/Rest';
import { useInterval } from 'usehooks-ts';

// material
import { Box, Container, Typography, Grid, Card, CardContent, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// components
import Page from '../components/theme/Page';
import { CalcBranchesStats } from '../components/mainPage/CompaniesTable';
import MediaControlCard from '../components/utils/MediaControlCard';
import BranchesTable from '../components/mainPage/BranchesTable';
import BranchForm from '../components/mainPage/BranchForm';
import ReactApexChart from 'react-apexcharts';
import Linkify from 'linkify-react';

// ----------------------------------------------------------------------

export default function DashboardCompany() {
  const { id } = useParams();
  const { rest } = useRest();
  const [companyData, setCompanyData] = useState(null);
  const [currentSongDetails, setCurrentSongDetails] = useState({ artist: "Unknown", song: "Unknown" });
  const [hasItemToAdd, setHasItemToAdd] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [pauseDataFetching, setPauseDataFetching] = useState(false);

  async function refreshCurrentSongDetails() {
    const data = await rest('get', `/company/current-song/${id}`);
    if (data)
      setCurrentSongDetails(data);
  }

  async function fetchData() {
    const data = await rest('get', `/company/${id}`);
    setCompanyData(data);

    let newChartData = [0, 0, 0];

    data.branches.forEach(branch => {
      if (branch.status === "new")
        newChartData[0]++;
      else if (branch.status === "available")
        newChartData[1]++;
      else if (branch.status === "unavailable")
        newChartData[2]++;
    });

    setChartData(newChartData);
  }

  useEffect(() => {
    refreshCurrentSongDetails();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function newCallback(data) {
    window.location.reload(false);
  }

  useInterval(
    () => {
      refreshCurrentSongDetails();
      if (!pauseDataFetching) {
        fetchData();
      }
    },
    10000, // interval in milliseconds
  );

  const chartOptions = {
    labels: ["New", "Available", "Unavailable"],
    "legend": {
      "show": true,
      "fontSize": 13,
      "position": "left",
      "horizontalAlign": "center",
      "markers": {
        "radius": 12
      },
      "fontWeight": 500,
      "itemMargin": {
        "horizontal": 12
      },
      "labels": {
        "colors": "#212B36"
      },
      "floating": false
    },
  }

  return (
    <Page title="Main">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 2 }}>{companyData?.company.name}</Typography>
        <Box sx={{
          display: "flex",
          pb: 5,
          justifyContent: "center"
        }}>

          <Grid container>
            <Grid item xs={4}>

              <Card variant="outlined" sx={{ mr: '10%', mb: 3 }}>
                <CardContent>
                  <Typography variant="h5" component="div">Company Details</Typography>
                  <Grid container>
                    <Grid item xs={6}>Availability:</Grid><Grid item xs={6}>
                      {(companyData) ? CalcBranchesStats(companyData) : "Loading..."}
                    </Grid>
                    {
                      companyData?.company.userIsAdmin && (<><Grid item xs={6}>Remarks:</Grid>
                        <Grid item xs={12}><Linkify>
                          {(companyData) ? companyData.company.remarks : "Loading..."}
                        </Linkify></Grid></>)
                    }
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={4}>
              {
                companyData && currentSongDetails ?
                  <MediaControlCard title={currentSongDetails.song} singer={currentSongDetails.artist} image={window.location.origin + ":3001/media/companies_logos/" + companyData.company.logo} src={companyData.company.playerUrl} />
                  : "Loading..."
              }
            </Grid>

            <Grid item xs={4}>
              <Card sx={{ py: 0 }}>
                {!chartData.length > 0
                  ? "Loading..."
                  : <ReactApexChart type="donut" series={chartData} options={chartOptions} height={160} />
                }
              </Card>
            </Grid>

          </Grid>
        </Box>
        {
          companyData?.company.userIsAdmin &&
          <Button
            sx={{ mb: 1 }}
            variant="contained"
            color="primary"
            onClick={() => setHasItemToAdd(true)}
          >
            New Branch
          </Button>
        }
        <Box sx={{ pb: 5 }}>
          {companyData ? (
            <BranchesTable branches={companyData.branches} ownerCompany={companyData.company} pauseDataFetchingState={setPauseDataFetching} />
          ) : ("Loading...")}
        </Box>
        <Box>
          <Dialog open={hasItemToAdd ? true : false} onClose={() => { setHasItemToAdd(false); }}>
            <DialogTitle>New Branch | Company {companyData?.company.name}</DialogTitle>
            <DialogContent>
              <BranchForm company={companyData?.company} submitCallback={newCallback} />
            </DialogContent>
          </Dialog>
        </Box>
      </Container>
    </Page>
  );
}
