import { Box } from '@mui/material';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Field } from 'formik';
import { Switch, TextField } from 'formik-mui';
import CFMForm from 'components/utils/CFMForm';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useRest } from 'components/utils/Rest';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useEffect } from 'react';
import { useServerConfig } from 'components/utils/common';

// ----------------------------------------------------------------------


const CustomizedSelectForFormik = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
        <Select
            name={name}
            value={value}
            onChange={e => {
                setFieldValue(name, e.target.value);
            }}
        >
            {children}
        </Select>
    );
};

const schema = Yup.object().shape({
    number: Yup.string().required("This field is required"),
    clientNumber: Yup.string().required("This field is required"),
    name: Yup.string().required("This field is required"),
    playerType: Yup.string().required("This field is required"),
    open: false,
    remarks: '',
});

const defaultValues = {
    number: '',
    clientNumber: '',
    name: '',
    playerType: '',
    openingTimeHour: '',
    openingTimeMin: '',
    closingTimeHour: '',
    closingTimeMin: '',
    open: false,
    remarks: '',
};

const itemToValues = (item) => {
    console.log(item);
    const openHour = item.openingTime?.split(':')[0];
    const openMin = item.openingTime?.split(':')[1];
    const closeHour = item.closingTime?.split(':')[0];
    const closeMin = item.closingTime?.split(':')[1];

    return {
        number: item.number !== "0" ? item.number : '',
        clientNumber: item.clientNumber || '',
        name: item.name,
        playerType: item.playerType !== "Unknown" ? item.playerType : '',
        openingTimeHour: openHour || '',
        openingTimeMin: openMin || '',
        closingTimeHour: closeHour || '',
        closingTimeMin: closeMin || '',
        open: item.open,
        remarks: item.remarks || '',
    }
}

export default function BranchForm({ itemToEdit, submitCallback, company }) {
    const { fetchServerConfig, serverConfigState } = useServerConfig();
    const { rest } = useRest();

    useEffect(() => {
        fetchServerConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function onSubmit(values) {
        if (values.openingTimeHour !== '' && values.openingTimeMin !== '' && values.closingTimeHour !== '' && values.closingTimeMin !== '') {
            const openHour = values.openingTimeHour.toString().padStart(2, '0');
            const openMin = values.openingTimeMin.toString().padStart(2, '0');
            const closeHour = values.closingTimeHour.toString().padStart(2, '0');
            const closeMin = values.closingTimeMin.toString().padStart(2, '0');
            values.openingTime = `${openHour}:${openMin}`;
            values.closingTime = `${closeHour}:${closeMin}`;
        } else {
            values.openingTime = '';
            values.closingTime = '';
        }

        let res;
        if (itemToEdit) {
            res = await rest('put', `/branch/${itemToEdit._id}`, values);
        }
        else {
            values.company = company.id;
            res = await rest('post', '/branch/add', values);
        }

        return res;
    }

    return (
        <CFMForm
            validationSchema={schema}
            onSubmit={onSubmit}
            initialValues={itemToEdit ? itemToValues(itemToEdit) : defaultValues}
            onSuccess={(res) => {
                console.log("res", res);
                submitCallback(res.newBranch);

                console.log('Successfully ' + itemToEdit ? 'updated' : 'created');

            }}
            onError={(res) => {
                console.log('Failed to update');
            }}
        >
            <Card>
                <CardContent>
                    <Grid container item>
                        <Grid item xs={6}>
                            <Box margin={1}>
                                <Field
                                    component={TextField}
                                    name="number"
                                    type="text"
                                    label="Branch Number"
                                    fullWidth
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box margin={1}>
                                <Field
                                    component={TextField}
                                    name="clientNumber"
                                    type="text"
                                    label="Client Number"
                                    fullWidth
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box margin={1}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Name"
                                    name="name"
                                    fullWidth
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box margin={1}>
                                <FormControl fullWidth>
                                    <InputLabel >Player Type</InputLabel>
                                    <Field name="playerType" component={CustomizedSelectForFormik}>
                                        {serverConfigState.PLAYER_TYPES?.map(playerType => (<MenuItem key={playerType} value={playerType}>{playerType}</MenuItem>))}
                                    </Field>
                                </FormControl>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box margin={1}>
                                <FormControlLabel
                                    control={
                                        <Field
                                            component={Switch}
                                            type="checkbox"
                                            name="open"
                                        />
                                    }
                                    label="Mark as open"
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={5}>
                            <Box margin={1}>
                                <Field
                                    component={TextField}
                                    name="openingTimeHour"
                                    label="Opening Hour"
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={5}>
                            <Box margin={1}>
                                <Field
                                    component={TextField}
                                    name="openingTimeMin"
                                    label="Opening Minute"
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={5}>
                            <Box margin={1}>
                                <Field
                                    component={TextField}
                                    name="closingTimeHour"
                                    label="Closing Hour"
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={5}>
                            <Box margin={1}>
                                <Field
                                    component={TextField}
                                    name="closingTimeMin"
                                    label="Closing Minute"
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box margin={1}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Remarks"
                                    name="remarks"
                                    fullWidth
                                    multiline
                                    rows={4}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </CFMForm >
    );
}
