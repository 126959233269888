import { Box, Typography, Button } from '@mui/material';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Field } from 'formik';
import { Switch, TextField, ToggleButtonGroup } from 'formik-mui';
import { ToggleButton } from '@mui/material';
import CFMForm from 'components/utils/CFMForm';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useRest } from 'components/utils/Rest';
import { useNavigate } from "react-router-dom";
import { ImageInput } from "formik-file-and-image-input/lib";

// ----------------------------------------------------------------------
export default function CompanyForm({ itemToEdit, submitCallback }) {
    const schema = Yup.object().shape({
        name: Yup.string().required("This field is required"),
        xmlUrl: Yup.string().required("This field is required"),
        playerUrl: Yup.string().required("This field is required"),
        currentSongXmlUrl: Yup.string().required("This field is required"),
        xmlUsername: Yup.string().required("This field is required"),
        xmlPassword: Yup.string().required("This field is required"),
        openingTimeHour: Yup.number().min(0).max(23).required("This field is required"),
        openingTimeMin: Yup.number().min(0).max(59).required("This field is required"),
        closingTimeHour: Yup.number().min(0).max(23).required("This field is required"),
        closingTimeMin: Yup.number().min(0).max(59).required("This field is required"),
        remarks: Yup.string(),
        logo: !itemToEdit ? Yup.mixed().required("This field is required") : Yup.mixed(),
        historyForManager: Yup.bool().required("This field is required"),
        emailForAlerts: Yup.string("This field must be a string"),
        companyOfflineAlertList: Yup.array().required("This field is required"),
        companyOfflineTimeout: Yup.number().required("This field is required"),
        branchOfflineAlertList: Yup.array().required("This field is required"),
        branchOfflineTimeout: Yup.number().required("This field is required"),
        unknownConnectionAlertList: Yup.array().required("This field is required"),
        unknownConnectionTimeout: Yup.number().required("This field is required"),
    });

    const imageFormats = ["image/png", "image/svg", "image/jpeg"];

    const defaultInitValues = {
        name: '',
        xmlUrl: '',
        playerUrl: '',
        currentSongXmlUrl: '',
        xmlUsername: '',
        xmlPassword: '',
        openingTimeHour: 0,
        openingTimeMin: 0,
        closingTimeHour: 0,
        closingTimeMin: 0,
        remarks: '',
        historyForManager: false,
        logo: null,
        emailForAlerts: '',
        companyOfflineAlertList: [],
        companyOfflineTimeout: 5,
        branchOfflineAlertList: [],
        branchOfflineTimeout: 5,
        unknownConnectionAlertList: [],
        unknownConnectionTimeout: 5,
    };

    const CustomImageInputWrapper = ({ onClick, fileName, src }) => {
        return (
            <div onClick={onClick}>
                {!src && <Button onClick={onClick}>Choose Image</Button>}
                <img src={src} />
            </div>
        )
    }

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);

            reader.readAsDataURL(file);
        });
    }

    const { rest } = useRest();
    const navigate = useNavigate();

    const NavigateToDashboard = () => {
        navigate('/dashboard');
    }

    async function onSubmit(values) {
        const openHour = values.openingTimeHour.toString().padStart(2, '0');
        const openMin = values.openingTimeMin.toString().padStart(2, '0');
        const closeHour = values.closingTimeHour.toString().padStart(2, '0');
        const closeMin = values.closingTimeMin.toString().padStart(2, '0');
        values.openingTime = `${openHour}:${openMin}`;
        values.closingTime = `${closeHour}:${closeMin}`;

        if (values.logo) {
            const imgExtension = values.logo.name.split('.').pop();
            const logoObj = {
                extension: '.' + imgExtension,
                size: values.logo.size,
                type: values.logo.type,
                imgBase64: await getBase64(values.logo),
            }

            values.logo = logoObj;
        } else {
            delete values.logo;
        }
        console.log("values", values);
        let res;
        if (itemToEdit) {
            res = await rest('put', '/company/' + itemToEdit.id, values);
        }
        else {
            res = await rest('post', '/company/add', values);
        }

        return res;
    }

    const itemToValues = (item) => {
        const openHour = item.openingTime.split(':')[0];
        const openMin = item.openingTime.split(':')[1];
        const closeHour = item.closingTime.split(':')[0];
        const closeMin = item.closingTime.split(':')[1];

        return {
            name: item.name,
            xmlUrl: item.xmlUrl,
            playerUrl: item.playerUrl ?? '',
            currentSongXmlUrl: item.currentSongXmlUrl ?? '',
            xmlUsername: item.xmlUsername,
            xmlPassword: item.xmlPassword,
            openingTimeHour: openHour,
            openingTimeMin: openMin,
            closingTimeHour: closeHour,
            closingTimeMin: closeMin,
            remarks: item.remarks,
            historyForManager: item.historyForManager,
            logo: "",
            emailForAlerts: item.emailForAlerts,
            companyOfflineAlertList: item.companyOfflineAlertList,
            companyOfflineTimeout: item.companyOfflineTimeout,
            branchOfflineAlertList: item.branchOfflineAlertList,
            branchOfflineTimeout: item.branchOfflineTimeout,
            unknownConnectionAlertList: item.unknownConnectionAlertList,
            unknownConnectionTimeout: item.unknownConnectionTimeout,
        }
    }

    return (
        <Card>
            <CardContent>
                <CFMForm
                    validationSchema={schema}
                    onSubmit={onSubmit}
                    initialValues={itemToEdit ? itemToValues(itemToEdit) : defaultInitValues}
                    onSuccess={(res) => {
                        console.log("res", res);
                        if (itemToEdit) {
                            submitCallback(res.newCompany);
                        }

                        console.log('Successfully ' + itemToEdit ? 'updated' : 'created');
                        NavigateToDashboard();
                    }}
                    onError={(res) => {
                        console.log('Failed to update');
                    }}
                >
                    <Grid container item>

                        <Grid item xs={6}>
                            <Box margin={1}>
                                <Field
                                    component={TextField}
                                    name="name"
                                    type="text"
                                    label="Company Name"
                                    helperText="Please Enter Email"
                                    fullWidth
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box margin={1}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Xml Url"
                                    name="xmlUrl"
                                    fullWidth
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box margin={1}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Player URL"
                                    name="playerUrl"
                                    fullWidth
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box margin={1}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Xml Url For Current Song"
                                    name="currentSongXmlUrl"
                                    fullWidth
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box margin={1}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Xml Username"
                                    name="xmlUsername"
                                    fullWidth
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box margin={1}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Xml Url Password"
                                    name="xmlPassword"
                                    fullWidth
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={3}>
                            <Box margin={1}>
                                <Field
                                    component={TextField}
                                    name="openingTimeHour"
                                    label="Opening Time - Hour"
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={3}>
                            <Box margin={1}>
                                <Field
                                    component={TextField}
                                    name="openingTimeMin"
                                    label="Opening Time - Minute"
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={3}>
                            <Box margin={1}>
                                <Field
                                    component={TextField}
                                    name="closingTimeHour"
                                    label="Closing Time - Hour"
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={3}>
                            <Box margin={1}>
                                <Field
                                    component={TextField}
                                    name="closingTimeMin"
                                    label="Closing Time - Minute"
                                />
                            </Box>
                        </Grid>


                        <Grid item xs={12}>
                            <Box margin={1}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Remarks"
                                    name="remarks"
                                    fullWidth
                                    multiline
                                    rows={4}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box margin={1}>
                                <FormControlLabel
                                    control={
                                        <Field
                                            component={Switch}
                                            type="checkbox"
                                            name="historyForManager"
                                        />
                                    }
                                    label="Allow company manager to see log and history"
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box margin={1}>
                                <Typography margin={1}>Logo</Typography>
                                <ImageInput
                                    name="logo"
                                    validFormats={imageFormats}
                                    component={CustomImageInputWrapper}
                                />
                            </Box>
                        </Grid>

                        {/* <Grid
                /////// TODO: Use it later //////
                  item xs={6}
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    component="img"
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      maxHeight: { xs: 150 },
                      maxWidth: { xs: 400 },
                      borderRadius: "20px",
                    }}
                    alt="logo"
                    src={logo.data}
                  />
                </Grid> */}

                        <Grid item xs={12} sx={{ my: 3 }}>
                            <Box margin={1}>

                                <hr></hr>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box margin={1}>
                                <Typography variant="h5">Permissions</Typography>
                            </Box>
                        </Grid>


                        <Grid item xs={12}>
                            <Box sx={{ mb: 3 }}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Company manager email for notifications"
                                    name="emailForAlerts"
                                    fullWidth
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box margin={1}>
                                <Typography>Notify in case of company become offline</Typography>
                                <Field component={ToggleButtonGroup} name="companyOfflineAlertList" type="checkbox">
                                    <ToggleButton value="companyManager" aria-label="left aligned">
                                        <Typography variant="button">Company Manager</Typography>
                                    </ToggleButton>
                                    <ToggleButton value="mainManager" aria-label="right aligned">
                                        <Typography variant="button">Main Manager</Typography>
                                    </ToggleButton>
                                </Field>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box sx={{ m: 1, mb: 4 }}>
                                <Field
                                    component={TextField}
                                    type="number"
                                    label="Notify after X minutes"
                                    name="companyOfflineTimeout"
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box margin={1}>
                                <Typography>Notify in case of brunch become offline</Typography>
                                <Field component={ToggleButtonGroup} name="branchOfflineAlertList" type="checkbox">
                                    <ToggleButton value="companyManager" aria-label="left aligned">
                                        <Typography variant="button">Company Manager</Typography>
                                    </ToggleButton>
                                    <ToggleButton value="mainManager" aria-label="right aligned">
                                        <Typography variant="button">Main Manager</Typography>
                                    </ToggleButton>
                                </Field>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box sx={{ m: 1, mb: 4 }}>
                                <Field
                                    component={TextField}
                                    type="number"
                                    label="Notify after X minutes"
                                    name="branchOfflineTimeout"
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box margin={1}>
                                <Typography>Notify in case of unknown connection</Typography>
                                <Field component={ToggleButtonGroup} name="unknownConnectionAlertList" type="checkbox">
                                    <ToggleButton value="companyManager" aria-label="left aligned">
                                        <Typography variant="button">Company Manager</Typography>
                                    </ToggleButton>
                                    <ToggleButton value="mainManager" aria-label="right aligned">
                                        <Typography variant="button">Main Manager</Typography>
                                    </ToggleButton>
                                </Field>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box sx={{ m: 1, mb: 4 }}>
                                <Field
                                    component={TextField}
                                    type="number"
                                    label="Notify after X minutes"
                                    name="unknownConnectionTimeout"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </CFMForm >
            </CardContent>
        </Card>
    );
}
