// component
import Iconify from '../../components/theme/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    t: 'sidebar.dashboard',
    path: '/dashboard',
    icon: getIcon('eva:pie-chart-2-fill')
  },
  {
    t: 'Unavailable Branches',
    path: '/unavailable-branches',
    icon: getIcon('eva:alert-circle-outline')
  },
  {
    t: 'sidebar.unknownPings',
    path: '/unknown-pings',
    icon: getIcon('eva:alert-circle-outline')
  },
  {
    t: 'sidebar.settings',
    icon: getIcon('ep:setting'),
    children: [
      {
        t: 'sidebar.settings.subchannels',
        path: '/settings/subchannels'
      },
      {
        t: 'sidebar.settings.users',
        path: '/settings/users'
      },
      {
        t: 'New Company',
        path: '/new-company',
        icon: getIcon('eva:file-add-fill')
      },
    ],
  },
];

export default sidebarConfig;
