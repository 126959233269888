// material
import * as React from 'react';
import { useState } from 'react';
import { Formik, Form } from 'formik';
import { Stack, Collapse, Snackbar, Alert } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { Box, Button, LinearProgress } from '@mui/material';

// ----------------------------------------------------------------------

export default function CFMForm({ children, validationSchema, onSubmit, initialValues, onSuccess, onError }) {
  const [serverMessage, setServerMessage] = useState();

  function unsetServerMessage() {
    setServerMessage(null);
  }

  return (
    <>
      <SnackbarProvider><Snackbar
        open={serverMessage?.success !== undefined}
        autoHideDuration={5000}
        onClose={unsetServerMessage}
        message={serverMessage?.success}
        sx={{ zIndex: 'snackbar' }} />
      </SnackbarProvider>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          console.log("submit", values);
          onSubmit(values).then((res) => {
            setSubmitting(false);
            setServerMessage(res);
            if (res.success) {
              resetForm();
              onSuccess && onSuccess(res);
            } else {
              onError(res.error);
            }
          });
        }}
      >
        {({ values, submitForm, resetForm, isSubmitting }) => (
          <Form><Stack spacing={3}>
            <Collapse in={serverMessage?.error ? true : false}>
              <Alert onClose={unsetServerMessage} severity="error">
                Error: {serverMessage?.error}
              </Alert>
            </Collapse>
            {children}
            {isSubmitting && <LinearProgress />}
            <Box margin={1}>
              <Button
                sx={{ margin: 1 }}
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Submit
              </Button>
              <Button
                sx={{ margin: 1 }}
                variant="contained"
                color="secondary"
                disabled={isSubmitting}
                onClick={resetForm}
              >
                Reset
              </Button>
            </Box>
          </Stack></Form>
        )}
      </Formik></>
  );
}
