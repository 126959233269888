import { useState, useEffect } from 'react';
import { useRest } from '../../components/utils/Rest';
// material
import { Container } from '@mui/material';
// components
import * as Yup from 'yup';
import ItemManager from './ItemManager';
import Page from '../../components/theme/Page';
import { Field } from 'formik';
import { Box } from '@mui/material';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import Typography from '@mui/material/Typography';

// ----------------------------------------------------------------------

export default function SettingsSubchannels() {
  function buildEntry(subchannel) {
    console.log(subchannel);
    return {
      id: subchannel._id,
      "Name": subchannel.name,
      "XML URL": subchannel.xmlUrl,
      "XML Username": subchannel.xmlUsername,
      "XML Password": subchannel.xmlPassword,
    };
  }

  function getInitValues(subchannel) {
    return {
      name: subchannel ? subchannel.name : '',
      xmlUrl: subchannel ? subchannel.xmlUrl : '',
      xmlUsername: subchannel ? subchannel.xmlUsername : '',
      xmlPassword: subchannel ? subchannel.xmlPassword : '',
    };
  }

  const schema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    xmlUrl: Yup.string().required("This field is required"),
    xmlUsername: Yup.string().required("This field is required"),
    xmlPassword: Yup.string().required("This field is required"),
  });

  return (
    <Page title="Settings | ClickFM">
      <Container maxWidth="xl">
        <ItemManager
          itemName="subchannel"
          buildTableEntry={buildEntry}
          getInitValues={getInitValues}
          itemColumns={["Name", "XML URL", "XML Username", "XML Password"]}
          validationSchema={schema}
        >
          <Box margin={1}>
            <Field
              component={TextField}
              name="name"
              label="Name"
            />
          </Box>
          <Box margin={1}>
            <Field
              component={TextField}
              name="xmlUrl"
              label="XML Address"
            />
          </Box>
          <Box margin={1}>
            <Field
              component={TextField}
              name="xmlUsername"
              label="Username"
            />
          </Box>
          <Box margin={1}>
            <Field
              component={TextField}
              name="xmlPassword"
              label="Password"
            />
          </Box>
        </ItemManager>
      </Container>
    </Page>
  );
}
